import React, { useState } from "react";
import bgImage from "../../Assets/loginbg.jpg";
import { Button, Form, Input } from "antd";
import CommonInput from "../../Common/CommonInput";
import { VendorForgotPassword, VendorLogin } from "../../ApiService/ApiFun";

import { dispatchtoast, makeLabel, setLocalStorageItem } from "../../Utils";
import { NavLink, useNavigate } from "react-router-dom";
import { FaEye, FaRegEyeSlash, FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import ForgotPassModal from "./ForgotPassModal";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleLogin = (val) => {
    setBtnLoading(true);
    VendorLogin(val)
      .then((res) => {
        if (res.status === "success") {
          setLocalStorageItem("accessToken", res.data.access_token);
          setLocalStorageItem("userDetail", res.data.user);
          if (res.data.user.is_kyc !== "0") {
            navigate("/dashboard");
          } else {
            navigate("/kyc");
          }
          window.location.reload();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleForgot = () => {
    if (!form.getFieldValue("loginId")) {
      dispatchtoast("Please Enter Mobile number First.", true);
    } else {
      VendorForgotPassword({ mobile_number: form.getFieldValue("loginId") })
        .then((res) => {
          if (res.status) {
            setDetail(form.getFieldValue("loginId"));
            setOpenForgotModal(true);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div
        className="flex justify-center md:justify-end items-center min-h-screen md:px-32 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className="w-96 bg-[#ffffff29] relative z-10 login-wrapper  bg-cover bg-center mx-4 shadow-[0_0_12px_-5px_#2b2a2a] backdrop-blur-sm p-8 rounded-lg"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <section className="font-inter">
            <div>
              <Form
                form={form}
                onFinish={(val) => handleLogin(val)}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name={"loginId"}
                  rules={[
                    {
                      required: true,
                      message: "Username or email Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  // label={makeLabel("Email/Mobile", "white")}
                >
                  {/* <CommonInput
                    prefix={<FaRegUser />}
                    placeHolder="Mobile or email address..."
                  /> */}
                  <div class="relative mt-4">
                    <div className="flex justify-start items-center gap-2">
                      <FaRegUser color="white" />
                      <input
                        type="text"
                        id="floatingInput"
                        class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                        placeholder="Mobile or email address..."
                      />
                      <label
                        for="floatingInput"
                        class="absolute left-6 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                      >
                        Mobile or email address...
                      </label>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Password Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  // label={makeLabel("Password", "white")}
                >
                  {/* <Input.Password
                    prefix={<RiLockPasswordFill />}
                    className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Password"
                  /> */}
                  <div class="relative mt-4">
                    <div className="flex justify-start items-center gap-2">
                      <RiLockPasswordFill color="white" />
                      <input
                        type={showPassword ? "text" : "password"}
                        id="floatingInput"
                        class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                        placeholder="Password"
                      />
                      <label
                        for="floatingInput"
                        class="absolute left-6 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                      >
                        Password
                      </label>
                      <p
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-0"
                      >
                        {showPassword ? (
                          <FaEye color="white" />
                        ) : (
                          <FaRegEyeSlash color="white" />
                        )}
                      </p>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item className="mt-8 mb-0">
                  <Button
                    className="bg-red-700 mb-2 rounded-none font-semibold font-inter"
                    block="true"
                    loading={btnLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </Form.Item>
                <div className="mb-20 font-inter">
                  <p
                    onClick={handleForgot}
                    className="text-white cursor-pointer flex justify-end items-center gap-2"
                  >
                    Forgot Password?{" "}
                  </p>{" "}
                  <p className="text-white mt-4 flex justify-start items-center gap-2">
                    Don't have an account?{" "}
                    <NavLink to="/register">
                      <p className="text-white font-inter">Sign Up?</p>
                    </NavLink>
                  </p>{" "}
                </div>
              </Form>
            </div>
          </section>
        </div>
      </div>
      {openForgotModal && (
        <ForgotPassModal
          open={openForgotModal}
          setOpen={setOpenForgotModal}
          detail={detail}
        />
      )}
    </>
  );
};

export default Login;
