import { lazy } from "react";
import PrivateAuth from "./PrivateAuth";
import NotFound from "./NotFound";
const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const Shops = lazy(() => import("../Views/Main/Shops/Shops"));
const Products = lazy(() => import("../Views/Main/Products/Products"));
const Customer = lazy(() => import("../Views/Main/Customer/Customer"));
const Request = lazy(() => import("../Views/Main/Request/Request"));
const Stock = lazy(() => import("../Views/Main/Stock/Stock"));
const MakeBill = lazy(() => import("../ProjectLayout/MakeBill"));
const Employee = lazy(() => import("../Views/Main/Employee/Employee"));
const StockTracing = lazy(() => import("../Views/Main/Stock/StockTracing"));
const Records = lazy(() => import("../Views/Main/Records/Records"));
const ExpiredProduct = lazy(() => import("../Views/Main/Shops/ExpiredProduct"));
const Kyc = lazy(() => import("../Views/Main/Kyc/Kyc"));
const OutOfStockProducts = lazy(() => import("../Views/Main/Shops/OutOfStockProduct"));

export const PrivateRoutes = () => {
  return [
    {
      element: <PrivateAuth />,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "/shops",
          name: "shops",
          element: <Shops />,
        },
        {
          path: "/expired",
          name: "shops",
          element: <ExpiredProduct />,
        },
        {
          path: "/out-of-stock",
          name: "shops",
          element: <OutOfStockProducts />,
        },
        {
          path: "/shop/products",
          name: "product",
          element: <Products />,
        },
        {
          path: "/customers",
          name: "customer",
          element: <Customer />,
        },
        {
          path: "/requests",
          name: "request",
          element: <Request />,
        },
        {
          path: "/shop/products/stocks",
          name: "stock",
          element: <Stock />,
        },
        {
          path: "/stock-tracing",
          name: "stock",
          element: <StockTracing />,
        },
        {
          path: "/billing",
          name: "billing",
          element: <MakeBill />,
        },
        {
          path: "/employee",
          name: "employee",
          element: <Employee />,
        },
        {
          path: "/sales",
          name: "sales",
          element: <Records />,
        },
        {
          path: "/kyc",
          name: "kyc",
          element: <Kyc />,
        },
        {
          path: "*",
          name: "*",
          element: <Kyc />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
