import { FormDataPost, FormDataPut, Get, getAPIUrl, MediaApi, Post, Put } from "./ApiMethod";
import { Auth, Main } from "./Endpoints";

export const VendorLogin = (data, param = "") => {
  const url = getAPIUrl(Auth.login, param);
  return Post(url, data);
};
export const VendorRegister = (data, param = "") => {
  const url = getAPIUrl(Auth.register, param);
  return Post(url, data);
};
export const VendorOtpVerify = (data, param = "") => {
  const url = getAPIUrl(Auth.otpverify, param);
  return Post(url, data);
};
export const VendorChangePassword = (data, param = "") => {
  const url = getAPIUrl(Auth.changePassword, param);
  return Post(url, data);
};
export const VendorForgotPassword = (data, param = "") => {
  const url = getAPIUrl(Auth.forgotPassword, param);
  return Post(url, data);
};
export const VendorForgotPasswordOtpVerify = (data, param = "") => {
  const url = getAPIUrl(Auth.forgotPassOtpVerify, param);
  return Post(url, data);
};
// Dashboard REGARDING FUNCTIONS

export const dashboardData = (param = "") => {
  const url = getAPIUrl(Main.dashboard.dashboard, param);
  return Get(url);
};
export const ExpiredProductData = (param = "") => {
  const url = getAPIUrl(Main.dashboard.expired, param);
  return Get(url);
};
export const OutOfStockData = (param = "") => {
  const url = getAPIUrl(Main.dashboard.outOfStock, param);
  return Get(url);
};
export const ChartData = (param = "") => {
  const url = getAPIUrl(Main.dashboard.chartData, param);
  return Get(url);
};
// SHOPS REGARDING FUNCTIONS

export const AddShops = (data, param = "") => {
  const url = getAPIUrl(Main.shops.registerShop, param);
  return Post(url, data);
};
export const EditShops = (data, param = "") => {
  const url = getAPIUrl(Main.shops.updateShops, param);
  return Put(url, data);
};
export const getShops = (param = "") => {
  const url = getAPIUrl(Main.shops.fetchShops, param);
  return Get(url);
};

// Product REGARDING FUNCTIONS

export const Addproduct = (data, param = "") => {
  const url = getAPIUrl(Main.product.addProduct, param);
  return Post(url, data);
};

export const getProducts = (param = "") => {
  const url = getAPIUrl(Main.product.getProduct, param);
  return Get(url);
};
export const updateProduct = (data, param = "") => {
  const url = getAPIUrl(Main.product.updateProduct, param);
  return Put(url, data);
};
// Customer REGARDING FUNCTIONS

export const Addcustomer = (data, param = "") => {
  const url = getAPIUrl(Main.customer.addCustomer, param);
  return Post(url, data);
};

export const getCustomer = (param = "") => {
  const url = getAPIUrl(Main.customer.getCustomer, param);
  return Get(url);
};

// Request REGARDING FUNCTIONS

export const AddRequest = (data, param = "") => {
  const url = getAPIUrl(Main.request.addRequest, param);
  return Post(url, data);
};

export const getRequest = (param = "") => {
  const url = getAPIUrl(Main.request.getRequest, param);
  return Get(url);
};

// category REGARDING FUNCTIONS

export const getcategory = (param = "") => {
  const url = getAPIUrl(Main.category.getCatehory, param);
  return Get(url);
};

// units REGARDING FUNCTIONS

export const getUnits = (param = "") => {
  const url = getAPIUrl(Main.units.fetchUnits, param);
  return Get(url);
};

// stock REGARDING FUNCTIONS

export const AddStock = (data, param = "") => {
  const url = getAPIUrl(Main.stock.addStock, param);
  return Post(url, data);
};

export const getStock = (param = "") => {
  const url = getAPIUrl(Main.stock.getStock, param);
  return Get(url);
};
export const traceStock = (param = "") => {
  const url = getAPIUrl(Main.stock.tracing, param);
  return Get(url);
};

export const stockPerUnit = (param = "") => {
  const url = getAPIUrl(Main.stock.stockPerUnit, param);
  return Get(url);
};
// payment REGARDING FUNCTIONS

export const getPaymentMethod = (param = "") => {
  const url = getAPIUrl(Main.paymentMethod.getPaymentMethod, param);
  return Get(url);
};

// invoice REGARDING FUNCTIONS

export const createInvoice = (data, param = "") => {
  const url = getAPIUrl(Main.createInvoice.crateInvoice, param);
  return MediaApi(url, data);
};

export const fetchSales = (param = "") => {
  const url = getAPIUrl(Main.createInvoice.fetchSales, param);
  return Get(url);
};
export const fetchBill = (param = "") => {
  const url = getAPIUrl(Main.createInvoice.fetchBill, param);
  return Get(url);
};
// EMPLOYEE REGARDING FUNCTIONS

export const Addemployee = (data, param = "") => {
  const url = getAPIUrl(Main.employee.createEmployee, param);
  return Post(url, data);
};

export const getemployee = (param = "") => {
  const url = getAPIUrl(Main.employee.fetchEmployee, param);
  return Get(url);
};

export const assignshop = (data, param = "") => {
  const url = getAPIUrl(Main.employee.assignShop, param);
  return Put(url, data);
};

export const updateAccess = (data, param = "") => {
  const url = getAPIUrl(Main.employee.updateAccess, param);
  return Post(url, data);
};

export const getEmprole = (param = "") => {
  const url = getAPIUrl(Main.employee.fetchEmprole, param);
  return Get(url);
};

// rOLE REGARDING FUNCTIONS
export const getRoles = (param = "") => {
  const url = getAPIUrl(Main.role.fetchRole, param);
  return Get(url);
};

// Profile REGARDING FUNCTIONS
export const getProfile = (param = "") => {
  const url = getAPIUrl(Main.profile.getProfile, param);
  return Get(url);
};
export const updateprofile = (data, param = "") => {
  const url = getAPIUrl(Main.profile.updateProfile, param);
  return FormDataPut(url, data);
};


// Profile REGARDING FUNCTIONS
export const uploadKycdoc = (data,param = "") => {
  const url = getAPIUrl(Main.kyc.uploadDoc, param);
  return FormDataPost(url,data);
};
export const getDocType = (param = "") => {
  const url = getAPIUrl(Main.kyc.doctype, param);
  return Get(url);
};
export const getAllDoc = ( param = "") => {
  const url = getAPIUrl(Main.kyc.alldocs, param);
  return Get(url);
};