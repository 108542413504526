export const Auth = {
  login: "vendor/auth/login",
  register: "vendor/auth/vendorSelfRegister",
  otpverify: "vendor/auth/otp-verify",
  changePassword: "vendor/auth/change-password",
  forgotPassword:"vendor/auth/forgot-password",
  forgotPassOtpVerify:"vendor/auth/forgot-password-verify"
};
export const Main = {
  dashboard: {
    dashboard: "vendor/report/genral-count",
    expired:"vendor/report/expiry-product",
    chartData:"vendor/report/chart-data",
    outOfStock:"vendor/report/out-of-stock-details"
  },
  role: {
    fetchRole: "vendor/auth//fetch-role",
  },
  shops: {
    registerShop: "vendor/shops/register-shop",
    fetchShops: "vendor/shops/fetch-shops",
    updateShops: "vendor/shops/update-shops-details",
  },
  product: {
    addProduct: "vendor/product/add",
    getProduct: "vendor/product/fetch-products",
    updateProduct: "vendor/product/update-products",
  },
  customer: {
    addCustomer: "vendor/customer/add",
    getCustomer: "vendor/customer",
  },
  request: {
    addRequest: "vendor/common/request",
    getRequest: "vendor/common/request",
  },
  category: {
    getCatehory: "vendor/category/fetch-category",
  },
  units: {
    fetchUnits: "vendor/common/fetch-units",
  },
  stock: {
    addStock: "vendor/stock/add",
    getStock: "vendor/stock/fetch-details",
    tracing: "vendor/stock/tracking",
    stockPerUnit: "vendor/stock/get-stocksasperunits",
  },
  paymentMethod: {
    getPaymentMethod: "vendor/common/fetch-payment-method",
  },
  createInvoice: {
    crateInvoice: "vendor/billing/create-invoice",
    fetchSales:"vendor/billing/fetch-sales",
    fetchBill:"vendor/billing/fetch-bills"
  },
  employee: {
    createEmployee: "vendor/auth/createEmp",
    fetchEmployee: "vendor/auth/emp-list",
    assignShop: "vendor/auth/assign-shop",
    updateAccess: "vendor/auth/update-access",
    fetchEmprole: "vendor/auth/fetch-role-access",
  },
  profile:{
    getProfile:"vendor/auth/fetch-profile",
    updateProfile:"vendor/auth/update-profile"
  },
  kyc:{
    uploadDoc:"vendor/kyc/upload-document",
    alldocs:"/vendor/kyc/uploaded-docs",
    doctype:"vendor/kyc/docs"
  }
};
