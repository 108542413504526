import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { VendorForgotPasswordOtpVerify } from "../../ApiService/ApiFun";
import { dispatchtoast, makeLabel } from "../../Utils";
import OTPInput from "react-otp-input";
import CommonInput from "../../Common/CommonInput";

const ForgotPassModal = ({ open, setOpen, detail }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const handleVerify = (val) => {
    setBtnLoading(true);
    const payload = {
      mobile_number: detail,
      otp: Number(val.otp),
      new_password: val.new_password,
    };
    VendorForgotPasswordOtpVerify(payload)
      .then((res) => {
        if (res.status) {
          setOpen(false);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
      <div>
        <Form onFinish={(val) => handleVerify(val)}>
          <Form.Item
            name={"otp"}
            rules={[
              {
                required: true,
                message: "Authentication Code Is Required",
              },
            ]}
            labelCol={{ span: 24 }}
            label={makeLabel("Enter OTP")}
          >
            <OTPInput
              //   value={otp}
              //   onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "80%",
                marginRight: "10px",
                height: "40px",
                border: "2px solid var(--primary)",
              }}
            />
          </Form.Item>
          <Form.Item
            name={"new_password"}
            rules={[
              {
                required: true,
                message: "New Password Is Required",
              },
            ]}
            labelCol={{ span: 24 }}
            label={makeLabel("Enter New Password")}
          >
            <CommonInput
              //   value={otp}
              //   onChange={setOtp}
              placeHolder="Enter New Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={btnLoading}
              type="primary"
              iconPosition="end"
              htmlType="submit"
              className="font-poppins bg-primary button-24  w-full mt-4  text-white font-bold rounded-none bg-secondary"
            >
              Verify Code
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ForgotPassModal;
