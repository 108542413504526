

import Avatar from "../Assets/Avatar.png";
import DummyImage from "../Assets/dummyuser.png";
import manThinking from "../Assets/man-thinking.png";
import Logo from "../Assets/logo.png";

export const Images = {

  Logo,
  Avatar,
  DummyImage,
  manThinking,
};
