import React, { useState } from "react";
import bgImage from "../../Assets/loginbg.jpg";
import { Button, Form, Input } from "antd";
import CommonInput from "../../Common/CommonInput";
import { VendorRegister } from "../../ApiService/ApiFun";

import { dispatchtoast, emailPattern, makeLabel } from "../../Utils";
import { NavLink } from "react-router-dom";
import { FaArrowRight, FaEye, FaRegEyeSlash } from "react-icons/fa";
import OtpModal from "./OtpModal";
const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const handleRegister = (val) => {
    setBtnLoading(true);
    let data = { ...val };
    delete data["confirmPassword"];
    VendorRegister(data)
      .then((res) => {
        if (res.status) {
          setDetail({
            token: res.data.verification_token,
            mobile: val.mobile_number,
          });
          setOpenOtpModal(true);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div
        className="flex justify-center md:justify-end items-center min-h-screen md:px-32 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className=" bg-[#ffffff29] relative z-10 login-wrapper  bg-cover bg-center mx-4 shadow-[0_0_12px_-5px_#2b2a2a] backdrop-blur-sm p-8 rounded-lg"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <section className="font-inter">
            <div>
              <Form
                onFinish={(val) => handleRegister(val)}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: " Name Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                >
                  <div class="relative mt-4">
                    <input
                      type="text"
                      id="floatingInput"
                      class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                      placeholder="First Name"
                    />
                    <label
                      for="floatingInput"
                      class="absolute left-2 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                    >
                      First Name
                    </label>
                  </div>
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "User Name Is Required.",
                    },
                  ]}
                  name={"mobile_number"}
                  labelCol={{ span: 24 }}
                >
                 <div class="relative mt-4">
                    <input
                      type="text"
                      id="floatingInput"
                      class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                      placeholder="Mobile Number"
                    />
                    <label
                      for="floatingInput"
                      class="absolute left-2 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                    >
                      Mobile Number
                    </label>
                  </div>
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                >
                  <div class="relative mt-4">
                    <input
                      type="text"
                      id="floatingInput"
                      class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                      placeholder="Email"
                    />
                    <label
                      for="floatingInput"
                      class="absolute left-2 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                    >
                      Email
                    </label>
                  </div>
                </Form.Item>
                <div className="grid grid-cols-2 place-items-start gap-x-4">
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Password Is Required",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                   <div class="relative mt-4">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="floatingInput"
                      class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                      placeholder="Password"
                    />
                    <label
                      for="floatingInput"
                      class="absolute left-2 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                    >
                      Password
                    </label>
                    <p
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-0 bottom-2"
                      >
                        {showPassword ? (
                          <FaEye color="white" />
                        ) : (
                          <FaRegEyeSlash color="white" />
                        )}
                      </p>
                  </div>
                  </Form.Item>{" "}
                  <Form.Item
                    name="confirmPassword"
                    // dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The passwords do not match!")
                          );
                        },
                      }),
                    ]}
                    labelCol={{ span: 24 }}
                  >
                   <div class="relative mt-4">
                    <input
                     type={showConfirmPass ? "text" : "password"}
                      id="floatingInput"
                      class="peer w-full border-b-2 text-white border-gray-300 bg-transparent px-2 py-1 placeholder-transparent focus:outline-none focus:border-primary"
                      placeholder="Confirm Password"
                    />
                    <label
                      for="floatingInput"
                      class="absolute left-2 -top-3.5 text-xs  transition-all duration-300 ease-in-out peer-placeholder-shown:top-1 peer-placeholder-shown:text-sm peer-focus:-top-3.5 peer-focus:text-xs text-primary"
                    >
                      Confirm Password
                    </label>
                    <p
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        className="absolute right-0 bottom-2"
                      >
                        {showConfirmPass ? (
                          <FaEye color="white" />
                        ) : (
                          <FaRegEyeSlash color="white" />
                        )}
                      </p>
                  </div>
                  </Form.Item>
                </div>
                <div className="flex justify-end mt-2 items-center">
                  <Button
                    loading={btnLoading}
                    icon={<FaArrowRight />}
                    iconPosition="end"
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins button-24 text-white font-bold rounded-none bg-red-700"
                  >
                    Create Account
                  </Button>
                </div>
                <div className="mb-20 mt-10 font-inter">
                  <p className="text-white flex justify-start items-center gap-2">
                    Already have an acount?{" "}
                    <NavLink to="/">
                      <p className="text-white font-inter">Sign in?</p>
                    </NavLink>
                  </p>{" "}
                </div>
              </Form>
            </div>
          </section>
        </div>
      </div>
      {openOtpModal && (
        <OtpModal
          open={openOtpModal}
          setOpen={setOpenOtpModal}
          detail={detail}
        />
      )}
    </>
  );
};

export default Login;
