import Login from "../Views/Auth/Login";
import SignUp from "../Views/Auth/Register";
import Kyc from "../Views/Main/Kyc/Kyc";
import PublicAuth from "./PublicAuth";


export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
            path: "/register",
            element: <SignUp />,
          },
          {
            path: "*",
            name: "*",
            element: <Kyc />,
          },
         
      ],
    },
  ];
};
