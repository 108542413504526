import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Images } from "../ImageController/Index";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageItem, setLocalStorageItem } from "../Utils";
import { getProfile, getRoles } from "../ApiService/ApiFun";
import ChangePassDrawer from "./ChangePassDrawer";
import ProfileDrawer from "./ProfileDrawer";
import { IoCreateSharp } from "react-icons/io5";
import { Layout } from "antd";
import { FaRegUser } from "react-icons/fa6";

const ProjectLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { access, role, is_kyc } = getLocalStorageItem("userDetail");
  const [profilePic, setProfilePic] = useState(null);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const [openchangePassDrawer, setChangePassDrawer] = useState(false);
  const [submenus, setSubMenus] = useState([
    {
      name: "Dashboard",
      acess: "dashboard",
      to: "/dashboard",
    },
    {
      name: "Sales",
      acess: "sales",
      to: "/sales",
    },
    {
      name: "Shop",
      acess: "shops",
      to: "/shops",
    },
    {
      name: "Product",
      acess: "product",

      to: "/shop/products",
    },
    {
      name: "Requests",
      acess: "requests",

      to: "/requests",
    },
    {
      name: "Customer",
      acess: "customer",
      to: "/customers",
    },
    {
      name: "Employee",
      acess: "employee",
      to: "/employee",
    },
  ]);
  const getAccess = () => {
    getRoles()
      .then((res) => {
        if (res.status === "success") {
          let data = { ...getLocalStorageItem("userDetail") };
          data = { ...data, access: res.data.map((i) => i?.roleType) };
          setLocalStorageItem("userDetail", data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getProfileDetail = () => {
    getProfile()
      .then((res) => {
        if (res.data.image_path !== null) {
          setProfilePic(res.data.image_path);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (!openProfileDrawer && is_kyc !== "0") {
      getProfileDetail();
    }
    //eslint-disable-next-line
  }, [openProfileDrawer]);
  useEffect(() => {
    is_kyc !== "0" && getAccess();
    //eslint-disable-next-line
  }, [location]);
  return (
    <>
      <div className="fixed w-full h-[125px] z-[99] ">
        <div className="flex justify-between bg-primary p-4 items-center">
          <NavLink to={"/dashboard"}>
            <LazyLoadImage src={Images.Logo} alt="logo" className="w-20 md:w-36 " />
          </NavLink>
          <div className="flex justify-center items-center gap-x-3">
            <div className="text-end">
              <p
                onClick={() => is_kyc !== "0" && setChangePassDrawer(true)}
                className="text-white font-inter font-semibold text-xs cursor-pointer hover:text-orange transition-all select-none"
              >
                Change Password
              </p>
              <p
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                className="text-white font-inter font-semibold text-xs cursor-pointer hover:text-orange transition-all mt-2 select-none"
              >
                Logout
              </p>
            </div>
            {profilePic ? (
              <LazyLoadImage
                effect="blur"
                onClick={() => is_kyc !== "0" && setOpenProfileDrawer(true)}
                src={
                  profilePic
                    ? "https://api.theinfomed.com" + profilePic
                    : Images.Avatar
                }
                onError={(e) => {
                  e.target.src = Images.Avatar;
                  e.target.alt = "userpic";
                }}
                alt="profile"
                className="w-12 h-12 cursor-pointer rounded-full"
              />
            ) : (
              <FaRegUser
                color="white"
                size={25}
                onClick={() =>is_kyc !== "0" && setOpenProfileDrawer(true)}
              />
            )}
          </div>
        </div>
        <div className="flex overflow-x-scroll justify-start px-4 shadow-lg text-xs md:text-sm font-inter shadow-gray-200 h-10 items-center gap-x-7 bg-white">
          {submenus.map((i, index) => (
            <NavLink
            key={index}
              className={`${
                role === "employee"
                  ? access.includes(i.acess.toLowerCase()) ||
                    i.acess === "dashboard" ||
                    i.acess === "sales"
                    ? "block"
                    : "hidden"
                  : role === "vendor"
                  ? i.acess === "product"
                    ? "hidden"
                    : "block"
                  : "block"
              }`}
              to={is_kyc !== "0" ? i.to : "/kyc"}
            >
              {" "}
              <p
                className={`${
                  location.pathname.includes(i.name.toLowerCase())
                    ? "text-primary font-bold underline underline-offset-4"
                    : "text-black font-semibold"
                } cursor-pointer `}
                key={index}
              >
                {i.name}
              </p>
            </NavLink>
          ))}
        </div>
      </div>

      <div className=" bg-gray-100 pt-[159px] pb-24 h-screen min-h-screen overflow-y-scroll  max-h-screen">
        {((role === "employee" &&
          access.includes("billing") &&
          location.pathname !== "/billing" &&
          is_kyc !== "0") ||
          (role === "vendor" &&
            location.pathname !== "/billing" &&
            is_kyc !== "0")) && (
          <div className="flex mx-8 justify-end items-center  pt-2">
            <NavLink to={"/billing"}>
              <div className="button-24 bg-primary flex justify-end items-center cursor-pointer gap-x-3 px-4 py-2 font-inter font-semibold text-white">
                <IoCreateSharp /> Create Bill
              </div>
            </NavLink>
          </div>
        )}
        <div className="mx-8 pt-2">{children}</div>
        {/* <Layout.Footer
          rootClassName="p-8"
          className="absolute bottom-0 w-full  bg-gray-200"
        >
          <div className="flex  justify-between  w-full items-center bg-primary font-inter text-white p-2 ">
            <p>@ 2024 All Rights Reserved (THE INFOMED )</p>
            <p className="cursor-pointer">Term & Conditions</p>
          </div>
        </Layout.Footer> */}
      </div>
      {openchangePassDrawer && (
        <ChangePassDrawer
          open={openchangePassDrawer}
          setOpen={setChangePassDrawer}
        />
      )}
      {openProfileDrawer && (
        <ProfileDrawer
          open={openProfileDrawer}
          setopen={setOpenProfileDrawer}
        />
      )}
    </>
  );
};

export default ProjectLayout;
