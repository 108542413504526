import React, { useEffect, useState } from "react";
import {
  getAllDoc,
  getDocType,
  uploadKycdoc,
} from "../../../ApiService/ApiFun";
import { Image, Spin, Table, Upload } from "antd";
import { IoCloudUpload } from "react-icons/io5";
import CommonInput from "../../../Common/CommonInput";
import { dispatchtoast, getLocalStorageItem, trimString } from "../../../Utils";
import { useNavigate } from "react-router-dom";

const Kyc = () => {
  const { is_kyc } = getLocalStorageItem("userDetail");
  const navigate = useNavigate();
  const [doctype, setDocType] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [list, setList] = useState({});
  const [field, setfield] = useState({});
  const [allDocList, setAllDocList] = useState([]);
  const getAllDocType = () => {
    setPageLoader(true);
    getDocType()
      .then((res) => {
        if (res.status === "success") {
          setList(
            res.data.reduce((a, b) => {
              a = { ...a, [b.document_name]: [] };
              return a;
            }, {})
          );
          setfield(
            res.data.reduce((a, b) => {
              a = { ...a, [b.document_name]: "" };
              return a;
            }, {})
          );
          setDocType(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  function getAllUploadedDoc() {
    getAllDoc()
      .then((res) => {
        if (res.status === "success") {
          setAllDocList(res.data);
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getAllDocType();
    getAllUploadedDoc();
    //eslint-disable-next-line
  }, []);
  const handleImageUpload = (file, from, id) => {
    try {
      // new Compressor(file, {
      //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      //   mimeType: "image/jpeg",
      //   success: (compressedResult) => {
      const reader = new FileReader();
      reader.onload = () => {
        setPageLoader(true);
        const formdata = new FormData();
        formdata.append("documentId", id);
        formdata.append("documents", file);
        formdata.append("documentDetails", field[from]);
        uploadKycdoc(formdata)
          .then((res) => {
            if (res.status === "success") {
              let data = { ...file };
              data.name = file.name;
              data.thumbUrl = URL.createObjectURL(file);
              setList({ ...list, [from]: [...list[from], data] });
              dispatchtoast(res.message);
              setfield({ ...field, [from]: "" });
              getAllUploadedDoc();
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setPageLoader(false));
      };
      reader.readAsDataURL(file);
      //   },
      // });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  };
  const columns = [
    {
      title: "Document Name",
      dataIndex: "document_name",
      key: "document_name",
      render: (text) => (
        <div className="w-auto">
          <p>{trimString(text ?? "--", 25)}</p>
        </div>
      ),
    },
    {
      title: "Document Detail",
      dataIndex: "document_details",
      key: "document_details",
      render: (text) => (
        <div className="w-auto">
          <p className="cursor-pointer">{trimString(text ?? "--", 50)}</p>
        </div>
      ),
    },
    {
      title: "Images",
      dataIndex: "document_details",
      key: "document_details",
      render: (text, record) => (
        <div className="w-[200px]  grid grid-cols-2 md:grid-cols-4 place-items-start gap-3">
          {record?.document_images.map((i, index) => {
            return (
              <div className="" key={index}>
                {!i.split("/")[3].includes("pdf") ? (
                  <Image
                    src={"https://api.theinfomed.com" + i}
                    alt="doc"
                    className="w-5 h-5  m-auto object-contain cursor-pointer"
                  />
                ) : (
                  <p
                    onClick={() =>
                      window.open("https://api.theinfomed.com" + i, "_blank")
                    }
                    className="bg-primary text-white px-3 cursor-pointer font-inter"
                  >
                    pdf
                  </p>
                )}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: "Verified",
      dataIndex: "is_verified",
      key: "is_verified",
      render: (text) => (
        <div className="w-auto">
          {text === "0" ? (
            <>
              <p className="cursor-pointer text-red-700 font-bold">
                Not Verified
              </p>
            </>
          ) : (
            <p className="cursor-pointer text-green-700 font-bold">Verifiedd</p>
          )}
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (is_kyc !== "0") {
      navigate(-1);
    }
    //eslint-disable-next-line
  }, [is_kyc]);
  return (
    <>
      <p className="text-center font-bold text-xl text-red-600 font-inter">
        Please complete your kyc!
      </p>
      <div className="bg-white p-4 mt-4 font-inter">
        <Spin spinning={pageLoader}>
          <div className="grid grid-cols-21 md:grid-cols-3 place-items-center gap-3">
            {doctype.map((i, index) => {
              return (
                <div key={index} className="w-full  h-full">
                  <p className="capitalize font-semibold">
                    {i.document_name.replace("_", " ")}
                  </p>
                  <div className="">
                    <Upload
                      maxCount={1}
                      listType="picture"
                      customRequest={({ file }) =>
                        handleImageUpload(file, i.document_name, i.id)
                      }
                      fileList={[]}
                      accept=".jpg,.jpeg,.png,.webp,.pdf"
                      {...props}
                      defaultFileList={[]}
                    >
                      <div className="flex justify-center mt-2 cursor-pointer items-center gap-4 bg-primary text-white  px-4 py-2 shadow shadow-gray-400  ">
                        <IoCloudUpload />
                        <p>Upload</p>
                      </div>
                    </Upload>

                    <div className="w-full mt-2">
                      <CommonInput
                        value={field[i.document_name]}
                        onChange={(e) =>
                          setfield({
                            ...field,
                            [i.document_name]: e.target.value,
                          })
                        }
                        placeHolder="Enter Document Detail"
                      />
                    </div>
                    <div className="grid grid-cols-3 mt-2 place-items-center gap-2 w-full">
                      {list[i.document_name].map((i, index) => {
                        return (
                          <div
                            key={index}
                            className="flex w-full h-full justify-start items-center gap-2 p-2 border border-gray-200 flex-wrap"
                          >
                            {!i.name?.includes(".pdf") ? (
                              <>
                                <img
                                  src={i.thumbUrl}
                                  alt="doc"
                                  className="w-8 h-8 rounded-full object-contain"
                                />
                                <p
                                  style={{ wordBreak: "break-all" }}
                                  className="text-xs"
                                >
                                  {i.name}
                                </p>
                              </>
                            ) : (
                              <p
                                style={{ wordBreak: "break-all" }}
                                className="text-xs"
                              >
                                {i.name}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Spin>
      </div>
      <p className="mt-4 text-red-500 font-semibold font-inter text-xs">
        Note: Please logout after all the documents have been verified.
      </p>
      <Table
        className="antd-table animate-fade-in mt-5 max-h-[500px]  overflow-x-auto text-white "
        columns={columns}
        pagination={false}
        dataSource={allDocList}
        loading={pageLoader}
        onRow={() => {
          return {
            className: "lowercase",
          };
        }}
      />
    </>
  );
};

export default Kyc;
