import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";

import { PrivateRoutes } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { getLocalStorageItem, ToastContainerMsg } from "./Utils";
import { useEffect, useState } from "react";

function App() {
  const [Routing, setRouting] = useState([
    ...PublicRoutes(),
    ...PrivateRoutes(),
  ]);
  const RouteConfig = () => useRoutes(Routing);
  useEffect(() => {
    if (getLocalStorageItem("accessToken")) {
      if (getLocalStorageItem("userDetail")?.is_kyc === "0") {
        let data = [...PrivateRoutes()];
        data[0].children = data[0]?.children.filter((i) => i.name === "kyc");
        setRouting([...PublicRoutes(), ...data]);
      } else {
        if (getLocalStorageItem("userDetail")?.role === "vendor") {
          setRouting([...PublicRoutes(), ...PrivateRoutes()]);
        } else {
          let data = [...PrivateRoutes()];
          data[0].children = data[0]?.children.filter(
            (i) =>
              getLocalStorageItem("userDetail")?.access?.includes(
                i.name?.toLowerCase()
              ) ||
              i.name === "dashboard" ||
              i.name === "sales" ||
              i.name === "*"
          );
          setRouting([...PublicRoutes(), ...data]);
        }
      }
    } else {
      setRouting([...PublicRoutes(), ...PrivateRoutes()]);
    }
  }, []);
  return (
    <>
      <HashRouter>
        <RouteConfig />
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
